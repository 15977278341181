import { useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import GradientButton from '../UI/GradientButton/GradientButton'
import Title from '../UI/Title/Title'
import styles from './Auth.module.css'
import 'react-toastify/dist/ReactToastify.css';

const LoginPage = props => {

   const inputEmail = useRef()
   const inputPassword = useRef()

   const navigate = useNavigate()

   useEffect(() => {
      if (props.authState.isAuth) {
         navigate('/')
      }
   }, [])

   const emptyInputEmailError = () => {
      toast.error('Email can not be empy!', {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
      });
   }

   const emptyInputPasswordError = () => {
      toast.error('Password can not be empy!', {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
      });
   }

   const credentialsInvalidError = () => {
      toast.error('Your credentials are incorrect!', {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
      });
   }

   const successLogin = () => {
      toast.success('Login successfully', {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
      });
   }

   const submitHandler = e => {
      e.preventDefault()
      if (!inputEmail.current.value.trim()) {
         emptyInputEmailError()
         return
      }

      if (!inputPassword.current.value.trim()) {
         emptyInputPasswordError()
         return
      }

      if (inputEmail.current.value === 'usertest' && inputPassword.current.value === '123') {
         successLogin()
         props.setAuthState(prevState => ({
            ...prevState,
            isAuth: true,
            authInfo: {
               login: 'usertest',
               password: '123'
            }
         }))
         navigate('/')
      } else {
         credentialsInvalidError()
      }
   }

   return <section className={styles.auth}>
      <div className={styles.container}>
         <Title size="h1">Login</Title>
         <form className={styles.auth__form} onSubmit={submitHandler}>
            <input ref={inputEmail} type="text" className={styles.auth__input} placeholder="Email"/>
            <input ref={inputPassword} type="password" className={styles.auth__input} placeholder="Password"/>
            <GradientButton className={styles.auth__btn} text="Sign In"/>
         </form>
         <p className={styles.auth__alternative}>
            Don't have an account? <Link className={styles['auth__alternative-link']} to="/register">Register now</Link>
         </p>
      </div>
   </section>
}

export default LoginPage