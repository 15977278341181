import { useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import GradientButton from '../UI/GradientButton/GradientButton'
import Title from '../UI/Title/Title'
import styles from './Auth.module.css'
import 'react-toastify/dist/ReactToastify.css';

const RegisterPage = props => {

   const inputEmail = useRef()
   const inputPassword = useRef()

   const navigate = useNavigate()

   useEffect(() => {
      if (props.authState.isAuth) {
         navigate('/')
      }
   }, [])

   const emptyInputEmailError = () => {
      toast.error('Email can not be empy!', {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
      });
   }

   const emptyInputPasswordError = () => {
      toast.error('Password can not be empy!', {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
      });
   }

   const successLogin = () => {
      toast.success('Register successfully', {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
      });
   }

   const submitHandler = e => {
      e.preventDefault()
      if (!inputEmail.current.value.trim()) {
         emptyInputEmailError()
         return
      }

      if (!inputPassword.current.value.trim()) {
         emptyInputPasswordError()
         return
      }

      
      props.setAuthState(prevState => ({
         ...prevState,
         isAuth: true,
         authInfo: {
            login: inputEmail.current.value,
            password: inputPassword.current.value
         }
      }))
      successLogin()
      navigate('/')
   }

   return <section className={styles.auth}>
      <div className={styles.container}>
         <Title size="h1">Register</Title>
         <form className={styles.auth__form} onSubmit={submitHandler}>
            <input ref={inputEmail} type="text" className={styles.auth__input} placeholder="Email"/>
            <input ref={inputPassword} type="password" className={styles.auth__input} placeholder="Password"/>
            <GradientButton className={styles.auth__btn} text="Sign Up"/>
         </form>
         <p className={styles.auth__alternative}>
            Have an account? <Link className={styles['auth__alternative-link']} to="/login">Login now</Link>
         </p>
      </div>
   </section>
}

export default RegisterPage