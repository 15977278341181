import styles from './Header.module.css'
import logotypeImg from '../../assets/img/logotype.png'
import { Link } from 'react-router-dom'
import HeaderMob from './HeaderMob/HeaderMob'
import { useEffect, useState } from 'react'

const Header = props => {

   const [isMobHeader, setIsMobHeader] = useState(false)
   const [isMobMenuOpen, setIsMobMenuOpen] = useState(false)

   const logoutHandler = e => {
      props.setAuthState(prevState => ({
         ...prevState,
         isAuth: false,
         authInfo: {
            login: null,
            password: null,
         }
      }))
   }

   useEffect(() => {
      function onResizeHandler() {
         if (window.innerWidth <= 790) {
            setIsMobHeader(true)
         } else {
            setIsMobHeader(false)
         }
      }

      window.addEventListener('resize', onResizeHandler)
      if (window.innerWidth <= 790) {
         setIsMobHeader(true)
      }

      return () => window.removeEventListener('resize', onResizeHandler)

   }, [])

   return <header>
      <div className={styles.header__inner}>
         <nav className={styles.header__menu}>
            {
               !isMobHeader ?
               <>
                  <div className={styles['header__menu-left']}>
                     <Link className={styles['header__logo-link']} to="/">
                        <img src={logotypeImg} alt="logotype" className={styles.header__logo} />
                     </Link>
                     <div className={styles['header__menu-inner']}>
                        <Link className={styles['header__menu-link']} to="/pricing">Pricing</Link>
                        <Link className={styles['header__menu-link']} to="/support">Support</Link>
                     </div>
                  </div>
                  <div className={styles['header__menu-right']}>
                     {props.authState.isAuth ? 
                        <>
                           <Link className={styles['header__menu-link']} to="/saved">Saved</Link>
                           <button onClick={logoutHandler} className={styles['header__menu-logout']}>Logout</button>
                        </>
                        :
                        <Link className={styles['header__menu-link']} to="/login">Login</Link>
                     }
                  </div>
               </> : 
               <>
                  <Link className={styles['header__logo-link']} to="/">
                     <img src={logotypeImg} alt="logotype" className={styles.header__logo} />
                  </Link>
                  <button onClick={(e) => setIsMobMenuOpen(true)} className={styles['header__menu-mobbtn']}>
                     <span></span>
                     <span></span>
                     <span></span>
                  </button>
                  </>
            }
            
         </nav>
      </div>
      {isMobHeader ? <HeaderMob setIsMobMenuOpen={setIsMobMenuOpen} isMobMenuOpen={isMobMenuOpen} logoutHandler={logoutHandler} authState={props.authState}/> : ''}
   </header>
}

export default Header