import styles from './ImageGeneratePreview.module.css'
import CanvasDraw from "react-canvas-draw";
import { useEffect, useRef, useState } from 'react';
import RangeInput from '../../UI/RangeInput/RangeInput';

const ImageGeneratePreview = ({ images, setIsUploadStep, setProgressLoader, progressLoader, setServerResponse}) => {

   const innerRef = useRef()
   const canvasRef = useRef()
   const [canvasHeight, setCanvasHeight] = useState()
   const [brushSize, setBrushSize] = useState([20])

   useEffect(() => {
      setCanvasHeight((images[0].height * innerRef.current.clientWidth) / images[0].width)
   }, [])

   const undressHandler = e => {
      console.log(canvasRef.current.getDataURL()) // For saving picture.
      setIsUploadStep('loader')
      function timeout() {
         setTimeout(() => {
            if (progressLoader < 100) {
               timeout()
               setProgressLoader(++progressLoader)
            } else {
               setServerResponse(images[0].src)
               setTimeout(() => {
                  setProgressLoader(0)
                  setIsUploadStep('final')
               }, 500)
               return
            }
         }, 50)
      }
      timeout()
   }

   const cancelHandler = e => {
      setIsUploadStep('dropzone')
   }

   return (
      <div ref={innerRef} className={styles.canvas__inner}>

         <div className={styles.canvas__text}>
            <p className={styles.canvas__instruction}>Please mark up nudification zone</p>
            <a href="https://youtube.com" className={styles.canvas__link}>Click here for video tutorial</a>
         </div>

         <RangeInput brushSize={brushSize} setBrushSize={setBrushSize} />
         <CanvasDraw ref={canvasRef} brushRadius={brushSize[0]} catenaryColor="transparent" brushColor="red" imgSrc={images[0].src} canvasWidth="500" canvasHeight={canvasHeight} hideGrid={true} style={
            {maxWidth: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}
         }/>
         <div className={styles.canvas__button}>
            <button onClick={undressHandler} className={`${styles['canvas__button-item']} ${styles['canvas__button-item--undress']}`}>Undress</button>
            <button onClick={cancelHandler} className={`${styles['canvas__button-item']} ${styles['canvas__button-item--cancel']}`}>Cancel</button>
         </div>
      </div>
    );
}

export default ImageGeneratePreview