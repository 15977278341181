import styles from './ModalTextGenerateButton.module.css'

import repeatIcon from '../../../../assets/img/repeatIcon.png'
import enchanceIcon from '../../../../assets/img/enchanceIcon.png'

const ModalTextGenerateButton = props => {
   return <div className={styles['button__inner']}>
      <button className={styles.button__item + ' ' + styles.blue}>
         <img src={repeatIcon} alt="Repeat" className={styles.button__icon} />
         <span className={styles.button__text}>Repeat</span>
      </button>
      <button className={styles.button__item + ' ' + styles.pink}>
         <img src={enchanceIcon} alt="Enhance" className={styles.button__icon} />
         <span className={styles.button__text}>Enhance</span>
      </button>
      <button className={styles.button__item}>
         <img src={repeatIcon} alt="Save" className={styles.button__icon} />
         <span className={styles.button__text}>Save</span>
      </button>
      <button className={styles.button__item}>
         <img src={enchanceIcon} alt="Download" className={styles.button__icon} />
         <span className={styles.button__text}>Download</span>
      </button>
   </div>
}

export default ModalTextGenerateButton