import ModalWithPortal from '../../UI/Modal/Modal'
import styles from './ModalTextGenerate.module.css'
import closeIcon from '../../../assets/img/closeIcon.png'
import userIcon from '../../../assets/img/userIcon.png'
import downloadIcon from '../../../assets/img/downloadIcon.png'
import modalImg from '../../../assets/img/modalImg.png'
import discordIcon from '../../../assets/img/discordIcon.png'
import ModalTextGenerateButton from './ModalTextGenerateButton/ModalTextGenerateButton'
import { Link } from 'react-router-dom'

const ModalTextGenerate = props => {

   const closeModalHandler = e => {
      props.setModalAuthOpen(false)
   }

   return <ModalWithPortal closeFunction={props.setModalAuthOpen} timeout={props.timeout} stateValue={props.stateValue} >
      <div className={styles.modal__inner}>
         <button className={styles.modal__close} onClick={closeModalHandler}>
            <img src={closeIcon} alt="close" className={styles.modal__closeicon} />
         </button>
         {
            (props.authState.isAuth && props.authState.authInfo.login) ? 
            <div className={styles['modal__auth-mob']}>
               <div className={styles.modal__username}>
                  <img src={userIcon} alt="user" className={styles['modal__username-icon']} />
                  <span className={styles['modal__username-text']}>{props.authState.authInfo.login}</span>
               </div>
               <button className={`${styles.modal__download} ${styles['modal__download-mob']}`}>
                  <img src={downloadIcon} alt="download" className={styles['modal__username-icon']} />
                  <span className={styles['modal__username-text']}>Download</span>
               </button>
            </div>
            :
            ''
         }
         <div className={styles.modal__result}>
            <div className={styles['modal__result-imgwrapper']}>
            {
               !props.authState.isAuth ? 
               <div className={styles['modal__result-imgblur']}>
                  <Link to="/pricing" className={styles['modal__result-imgblurplan']}>Select a Plan</Link>
                  <a href="https://discord.com" className={styles['modal__result-imgblurlink']}>
                     <img src={discordIcon} alt="discord logotype" className={styles['modal__result-imgblurdiscord']} />
                     Try for free in Discord
                  </a>
               </div>
               :
               ''
            }
               <img src={modalImg} alt="result" className={styles['modal-result-img']} />
            </div>
            
            <div className={styles['modal__result-info']}>
               <div className={styles['modal__result-info-promt']}>
                  <h2 className={styles['modal__result-info-promt-title']}>
                     Prompt:
                  </h2>
                  <p className={styles['modal__result-info-promt-text']}>
                     {props.textRequest}
                  </p>
               </div>
               <p className={styles['modal__result-info-remain']}>
                  Remaining Generations: <b>40</b>
               </p>
               {props.authState.isAuth ? <ModalTextGenerateButton /> : ''}
               <div className={styles['modal__result-infolist']}>
                  <div className={styles['modal__result-infolist-item']}>
                     <span className={styles['modal__result-infolist-label']}>Model</span>
                     <span className={styles['modal__result-infolist-text']}>stable-diffusion</span>
                  </div>
                  <div className={styles['modal__result-infolist-item']}>
                     <span className={styles['modal__result-infolist-label']}>Model Version</span>
                     <span className={styles['modal__result-infolist-text']}>v1.5</span>
                  </div>
                  <div className={styles['modal__result-infolist-item']}>
                     <span className={styles['modal__result-infolist-label']}>Guidance Scale</span>
                     <span className={styles['modal__result-infolist-text']}>7.5</span>
                  </div>
                  <div className={styles['modal__result-infolist-item']}>
                     <span className={styles['modal__result-infolist-label']}>Dimensions</span>
                     <span className={styles['modal__result-infolist-text']}>512x512</span>
                  </div>
                  <div className={styles['modal__result-infolist-item']}>
                     <span className={styles['modal__result-infolist-label']}>Seed</span>
                     <span className={styles['modal__result-infolist-text']}>44529009838123</span>
                  </div>
                  <div className={styles['modal__result-infolist-item']}>
                     <span className={styles['modal__result-infolist-label']}>Steps</span>
                     <span className={styles['modal__result-infolist-text']}>50</span>
                  </div>
               </div>
            </div>
         </div>
         {
            props.authState.isAuth ? 
            <button className={styles.modal__download}>
               <img src={downloadIcon} alt="download" className={styles['modal__username-icon']} />
               <span className={styles['modal__username-text']}>Download</span>
            </button>
            :
            ''
         }
      </div>
   </ModalWithPortal>
}

export default ModalTextGenerate