import { Link } from 'react-router-dom'
import Title from '../../UI/Title/Title'
import ImageGenerateDragAndDrop from '../ImageGenerateDragAndDrop/ImageGenerateDragAndDrop'
import styles from './ImageGenerate.module.css'

const ImageGenerate = props => {
   return <section className={styles.imagegenerate}>
      <Title size="h2">Nudify with AI</Title>
      <ImageGenerateDragAndDrop authState={props.authState}/>
      <p className={styles.imagegenerate__text}>By uploading an image you hereby to our <Link className={styles['imagegenerate__text-link']} to="/terms-of-service">Terms of service.</Link> This site is protected by re CAPTCHA and the Google <a href="https://google.com" className={styles['imagegenerate__text-link']}>Privacy Policy</a> and <a href="https://google.com" className={styles['imagegenerate__text-link']}>Terms of Service apply.</a></p>
   </section>
}

export default ImageGenerate