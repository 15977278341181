import styles from './InstructionBlock.module.css'

const InstructionBlock = props => {
   return <div className={styles.instruction}>
      <div className={styles.instruction__item}>
         <span className={styles.instruction__head}>1</span>
         <div className={styles.instruction__body}>
            <div className={styles['instruction__image-wrapper']}>
               <img src="https://picsum.photos/600/800" alt="zoom out" className={styles.instruction__image} />
            </div>
            <p className={styles.instruction__text}>
               Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem cum doloremque distinctio facilis enim maiores ea ipsum earum? Eos quam quo cupiditate.
            </p>
         </div>
      </div>
      <div className={styles.instruction__item}>
         <span className={styles.instruction__head}>2</span>
         <div className={styles.instruction__body}>
            <div className={styles['instruction__image-wrapper']}>
               <img src="https://picsum.photos/600/1000" alt="zoom out" className={styles.instruction__image} />
            </div>
            <p className={styles.instruction__text}>
               Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem cum doloremque distinctio facilis enim maiores ea ipsum earum? Eos quam quo cupiditate.
            </p>
         </div>
      </div>
      <div className={styles.instruction__item}>
         <span className={styles.instruction__head}>3</span>
         <div className={styles.instruction__body}>
            <div className={styles['instruction__image-wrapper']}>
               <img src="https://picsum.photos/700/1000" alt="zoom out" className={styles.instruction__image} />
            </div>
            <p className={styles.instruction__text}>
               Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quidem cum doloremque distinctio facilis enim maiores ea ipsum earum? Eos quam quo cupiditate.
            </p>
         </div>
      </div>
   </div>
}

export default InstructionBlock