import GradientButton from '../../UI/GradientButton/GradientButton'
import styles from './TextGenerate.module.css'

import searchIcon from '../../../assets/img/searchIcon.png'
import { useEffect, useRef, useState } from 'react'
import ModalTextGenerate from '../ModalTextGenerate/ModalTextGenerate'
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextGenerateLoader from '../TextGenerateLoader/TextGenerateLoader'
import { CSSTransition } from 'react-transition-group'

const TextGenerate = props => {

   const [isResultModalOpen, setIsResultModalOpen] = useState(false)
   const [isLoaderShow, setIsLoaderShow] = useState(false)
   const [textRequest, setTextRequest] = useState('')

   const inputRef = useRef()

   const emptyInputError = () => {
      toast.error('Prompt can not be empy!', {
         position: "top-right",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "colored",
      });
   }

   const formSubmitHandler = e => {
      e.preventDefault()
      if (!inputRef.current.value.trim()) {
         emptyInputError()
         return
      }
      setIsLoaderShow(true)
      setTextRequest(inputRef.current.value)
      setTimeout(() => {
         setIsLoaderShow(false)
         setIsResultModalOpen(true)
         inputRef.current.value = ''
      }, 3000)
   }

   return <>
   <ModalTextGenerate textRequest={textRequest} setModalAuthOpen={setIsResultModalOpen} stateValue={isResultModalOpen} timeout={500} authState={props.authState} />
   <form className={styles.input__inner} onSubmit={formSubmitHandler}>
      <div className={styles.input__left} onClick={() => inputRef.current.focus()}>
         <img src={searchIcon} alt="search" className={styles.input__icon} />
         <input ref={inputRef} className={styles.input__input} type="text" placeholder='Describe what you want to see' />
      </div>
      <GradientButton text="Generate" className={styles.input__button}/>
   </form>
   <CSSTransition
      unmountOnExit
      mountOnEnter
      in={isLoaderShow}
      timeout={500}
      classNames='loaderfrompromt__animation'
   >
   <TextGenerateLoader />
   </CSSTransition>
   </>
}

export default TextGenerate