import styles from './ImageGenerateResult.module.css'

import downloadIcon from '../../../assets/img/downloadIconBlack.png'

const ImageGenerateResult = props => {

   const downloadHandler = e => {
      console.log('download')
   }

   const uploadAnother = e => {
      props.setIsUploadStep('dropzone')
   }

   return <div className={styles.result}>
      <p className={styles.result__text}>
         Done. <button onClick={uploadAnother} className={styles.result__link}>Upload Another?</button>
      </p>
      <img className={styles.result__img} src={props.resultSrc} alt="result" />
      <div className={styles.result__buttons}>
         <span className={styles.result__empty}></span>
         <button onClick={uploadAnother} className={`${styles['canvas__button-item']}`}>Upload Another</button>
         <div className={styles.result__saves}>
            <button className={styles.result__save}>Save</button>
            <button onClick={downloadHandler} className={styles.result__download}><img src={downloadIcon} alt="download" /></button>
         </div>
      </div>
   </div>
}

export default ImageGenerateResult