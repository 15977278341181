import styles from './MainPage.module.css'
import TextGenerate from './TextGenerate/TextGenerate'
import topHomeImg from '../../assets/img/topHomeImg.png'
import ImageGenerate from './ImageGenerate/ImageGenerate'
import Title from '../UI/Title/Title'
import InstructionBlock from './InstructionBlock/InstructionBlock'

const MainPage = props => {

   return <section className={styles.home}>
      <div className={styles.container}>
         <Title size="h1">Generate from Text with AI</Title>
         <TextGenerate authState={props.authState} />
         <img className={styles.home__topimg} src={topHomeImg} />
         <ImageGenerate authState={props.authState} />
         <InstructionBlock />
      </div>
   </section>
}

export default MainPage